import "core-js/modules/es.function.name.js";
import service from '@/utils/request';

// @Tags DomainRR
// @Summary 创建DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DomainRR true "创建DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Domain/createDomainRR [post]
export var createDomainRR = function createDomainRR(data) {
  return service({
    url: "/domains/" + data.name,
    method: 'post',
    data: data
  });
};

// @Tags DomainRR
// @Summary 批量创建DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DomainRR true "批量创建DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /domains [post]
export var batchCreateDomain = function batchCreateDomain(data, zone) {
  return service({
    url: "/domains" + "?zone=" + zone,
    method: 'post',
    data: data
  });
};

// @Tags DomainRR
// @Summary 删除DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DomainRR true "删除DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Domain/deleteDomainRR [delete]
export var deleteDomainRR = function deleteDomainRR(data) {
  return service({
    url: "/domains/" + data.domain + "?zone=" + data.zone,
    method: 'delete',
    data: data
  });
};

// @Tags DomainRR
// @Summary 删除DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Domain/batchDeleteDomain [delete]
export var batchDeleteDomain = function batchDeleteDomain(data, zone) {
  return service({
    url: "/domains" + "?zone=" + zone,
    method: 'delete',
    data: data
  });
};

// @Tags DomainRR
// @Summary 更新DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DomainRR true "更新DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /Domain/updateDomainRR [put]
export var updateDomainRR = function updateDomainRR(data) {
  return service({
    url: "/domains/full-sized/" + data.name,
    method: 'put',
    data: data
  });
};

// @Tags DomainRR
// @Summary 用id查询DomainRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DomainRR true "用id查询DomainRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /Domain/findDomainRR [get]
export var findDomainRR = function findDomainRR(params) {
  return service({
    url: "/domains/" + params.domain,
    method: 'get',
    params: params
  });
};

// @Tags DomainRR
// @Summary 分页获取DomainRR列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取DomainRR列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Domain/getDomainRRList [get]
export var getDomainRRList = function getDomainRRList(params) {
  return service({
    url: "/zones/" + params.zone,
    method: 'get',
    params: params
  });
};

// @Tags Domain
// @Summary Update a domain status
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param zone query string true "ZoneFQDN"
// @Param domain query string true "DomainFQDN"
// @Param status query string true "true/false"
// @Success 200 {object} response.Response "{"code":0,"data":{},"msg":"Action successful"}"
// @Router /domains/updatedomainstatus [put]
export var updateDomainStatus = function updateDomainStatus(data) {
  return service({
    url: "/domains/updatedomainstatus" + "?zone=" + data.zone + "&domain=" + data.domain + "&status=" + data.status,
    method: 'put',
    data: data
  });
};