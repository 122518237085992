var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "domain-import"
  }, [_c("el-page-header", {
    staticClass: "domain-header",
    attrs: {
      content: this.$route.query.zone
    },
    on: {
      back: _vm.goBack
    }
  }), _c("div", {
    staticClass: "import-content"
  }, [_c("el-card", {
    attrs: {
      shadow: "never"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "import-content-title"
  }, [_vm._v("Zone file")]), _c("div", {
    staticClass: "import-content-desc"
  }, [_vm._v(_vm._s(_vm.$t("domain.import-content-subtitle")))])]), _c("div", [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 10,
      placeholder: "example\t600\tIN\tA\t98.98.98.98"
    },
    nativeOn: {
      keyup: function keyup($event) {
        return _vm.setRecord.apply(null, arguments);
      }
    },
    model: {
      value: _vm.importContent,
      callback: function callback($$v) {
        _vm.importContent = $$v;
      },
      expression: "importContent"
    }
  })], 1)]), _c("el-alert", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contentErr.length > 0,
      expression: "contentErr.length>0"
    }],
    attrs: {
      type: _vm.contentErrType,
      "show-icon": "",
      closable: false
    }
  }, [_c("div", [_c("ul", _vm._l(_vm.contentErr, function (item, idx) {
    return _c("li", {
      key: idx
    }, [_vm._v(_vm._s(item))]);
  }), 0)])]), _c("i", {
    staticClass: "import-content-tip"
  }, [_vm._v(_vm._s(_vm.$t("domain.import-content-tip")))])], 1), _c("div", {
    staticClass: "import-content"
  }, [_c("div", [_c("el-card", {
    attrs: {
      shadow: "never"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "import-content-title"
  }, [_vm._v(_vm._s(_vm.recordPreViewTitle))]), _c("div", {
    staticClass: "import-content-desc"
  }, [_vm._v(_vm._s(_vm.$t("domain.import-table-subtitle")))])]), _c("div", [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.rrs
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: _vm.$t("domain.table-header-record-name")
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "ttl",
      label: "TTL"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: _vm.$t("domain.table-header-record-type")
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: _vm.$t("domain.table-header-record-value")
    }
  }), _c("div", {
    attrs: {
      slot: "empty"
    },
    slot: "empty"
  }, [_vm._v(" " + _vm._s(_vm.$t("domain.import-table-content-empty")) + " ")])], 1)], 1)])], 1), _c("div", {
    staticClass: "import-pager"
  }, [_c("el-pagination", {
    attrs: {
      small: "",
      layout: "sizes, prev, pager, next",
      "pager-count": 5,
      "page-sizes": [50, 100, 200, 500, 1000],
      "current-page": _vm.page,
      "page-size": _vm.pageSize,
      total: _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  })], 1)]), _c("div", {
    staticClass: "import-btn"
  }, [_c("div", [_c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: _vm.saveImport
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.import")))])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };